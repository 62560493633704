import React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  useAccordionContext,
  useAccordionItemContext,
} from '@reach/accordion';
import classnames from 'classnames';

import * as style from './Hero.module.css';
import { Heading, Margins } from '../../../components';
import { useTranslation } from '../../../hooks';

function MyAccordionButton({ item, ...props }) {
  let { id } = useAccordionContext();
  let { isExpanded } = useAccordionItemContext();
  return (
    <Heading
      level="h3"
      visualLevel="h6"
      position="left"
      className={classnames(style['hero__accordionHeading'], {
        [style['isExpanded']]: isExpanded,
      })}
    >
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 16 16"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        className={style['hero__accordionIcon']}
      >
        <path
          fillRule="evenodd"
          d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
        />
      </svg>
      <AccordionButton
        {...props}
        data-parentid={id}
        className={style['hero__accordionButton']}
      >
        {item.HEADER}
      </AccordionButton>
    </Heading>
  );
}

const Hero = () => {
  const { t, translations } = useTranslation();

  return (
    <section className={style['hero']}>
      <Margins>
        <Heading level="h2" visualLevel="h5" highlight weight="bold">
          {t('HOMEPAGE.ACCORDION_HEADER')}
        </Heading>
        <div className={style['hero__content']}>
          <Accordion collapsible>
            {translations.HOMEPAGE.ACCORDION_ITEMS.map((item, index) => (
              <AccordionItem
                className={style['hero__accordionItem']}
                key={index}
              >
                <MyAccordionButton item={item} />
                <AccordionPanel className={style['hero__accordionPanel']}>
                  {item.TEXT}
                </AccordionPanel>
              </AccordionItem>
            ))}
          </Accordion>
        </div>
      </Margins>
    </section>
  );
};

export default Hero;
