import React from 'react';

import Layout from '../containers/Layout/Layout';
import { useTranslation } from '../hooks';
import { Heading, Margins, Container, Seo, List } from '../components';
import { Hero } from '../containers/Homepage';

const Homepage = ({ pageContext: { currentPath } }) => {
  const { t, te, translations, locale } = useTranslation();

  return (
    <Layout>
      <Seo location={currentPath} lang={locale} />
      <Container>
        <Margins size="2x">
          <Margins tag="section" size="2x">
            <Heading level="h1" visualLevel="h3">
              {t('HOMEPAGE.HEADER')}
            </Heading>

            <Margins>
              {translations.HOMEPAGE.WELCOME_TEXTS?.map((text, index) => (
                <p key={index} dangerouslySetInnerHTML={{ __html: text }} />
              ))}
            </Margins>

            {te('HOMEPAGE.APPOINTMENT_DISCLAIMER') && (
              <Heading
                level="h2"
                visualLevel="h6"
                weight="bold"
                color="secondary"
              >
                {t('HOMEPAGE.APPOINTMENT_DISCLAIMER')}
              </Heading>
            )}

            <Margins size="05x">
              <Heading level="h2" visualLevel="h4">
                {t('HOMEPAGE.WELCOME')}
              </Heading>
              <List items={translations.HOMEPAGE.WELCOME_INFO} type="hyphen" />
            </Margins>

            {/*<Margins size="05x">*/}
            {/*  <Heading*/}
            {/*    level="h2"*/}
            {/*    visualLevel="h6"*/}
            {/*    weight="bold"*/}
            {/*    color="secondary"*/}
            {/*  >*/}
            {/*    {t('HOMEPAGE.APPOINTMENT')}*/}
            {/*  </Heading>*/}
            {/*  {translations.HOMEPAGE.APPOINTMENT_INFO.map((text, index) => (*/}
            {/*    <p className="text-small" key={index}>*/}
            {/*      {text}*/}
            {/*    </p>*/}
            {/*  ))}*/}
            {/*</Margins>*/}
          </Margins>

          <Hero />
        </Margins>
      </Container>
    </Layout>
  );
};

export default Homepage;
